.background-texture {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("/images/circuit-board.svg");
  background-color: transparent;
  fill: white;
  opacity: 0.09;
  z-index: -1;
}
