@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

.portfolio {
  * {
    font-family: "Montserrat", sans-serif;
    font-weight: 100;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
  
    color: white;
  }
}
