.projects-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1em;

  justify-content: space-between;

  .project {
    width: 32%;

    .project-media {
      display: flex;
      justify-content: center;
      background-color: black;
    }

    &:hover {
      cursor: pointer;

      .expand-icon {
        animation: bobbing 0.5s;
        animation-iteration-count: infinite;
      }
    }

    // .project-media {
    //   .project-media-image {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //   }
    // }

    .expandable.collapsed {
        position: relative;
        
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, transparent 90%, #adb5bd);
      }
    }

    .project-body {
      background-color: rgb(250, 250, 250);
      padding: 1em;

      * {
        color: black;
      }

      h1 {
        color: rgb(0, 83, 156);
        font-weight: bold;
      }

      .links {
        display: flex;
        justify-content: space-evenly;

        button {
          flex: 1;
          margin: 1em;
          padding: 0.5em;
        }
      }
    }

    .project-date-started {
      background-color: rgb(52, 58, 64);
      color: white;
      padding: 0.5em;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @keyframes bobbing {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(5px);
        }
        100% {
          transform: translateY(0);
        }
      }

      .expand-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        &.expanded svg {
          transform: rotate(180deg);
        }

        svg {
          transform: rotate(0deg);
          transition: transform 0.3s;
          fill: white;
          height: 1.5em;
          width: 1.5em;
        }
      }
    }
  }
}
