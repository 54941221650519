@use "../../master";

.main-content {
  $defaultPadding: master.$defaultPadding;

  padding: $defaultPadding;
  background-color: #dc4c46;
  height: auto;
  z-index: 1;
  position: relative;

  h1 {
    margin: 0;
  }

  h1,
  p {
    position: relative;
    z-index: 1;
  }
}
