@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner{height:auto;padding:50px 50px 50px 100px;padding-bottom:10px;background:#343f91;background:linear-gradient(90deg, #343f91 0%, #41cdde 100%);color:#fff;position:relative}.banner .particles{position:absolute;width:100%;height:100%;top:0;left:0}.banner .bannerContainer{display:flex;margin:0 20px;height:100%;justify-content:space-between;align-items:center;flex-direction:row;flex:1 1}@media screen and (max-width: 1024px){.banner .bannerContainer{flex-direction:column}}.banner .bannerContainer .overviewContainer{display:flex;flex-direction:column;justify-content:center;align-items:center;flex:1 1;height:100%}.banner .bannerContainer .overviewContainer h1{font-size:3rem;font-weight:100;margin:0}.banner .bannerContainer .overviewContainer .overview{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100%;width:100%;flex-grow:1}.banner .bannerContainer .overviewContainer .overview *{width:100%}.banner .bannerContainer .overviewContainer .overview .name{display:flex;flex-grow:flex-start;justify-content:left;align-items:end;border-bottom:1px solid #fff}.banner .bannerContainer .overviewContainer .overview .statement{flex-grow:1}.banner .bannerContainer .skillsContainer{display:flex;flex-direction:column;justify-content:center;align-items:start;flex:1 1;padding:0 20px;height:100%;position:relative;z-index:100}.banner .bannerContainer .skillsContainer .skillsTitle{width:100%}.banner .bannerContainer .skillsContainer .skillsTitle h1{font-size:3rem;margin:0}.banner .bannerContainer .skillsContainer .skillsDetail{display:flex;flex-direction:column;width:100%;height:100%}
#tsparticles{height:inherit}#tsparticles canvas{height:inherit !important;position:-webkit-sticky !important;position:sticky !important}
.skills-content{display:flex;flex-direction:row;align-items:center;justify-content:left;width:100%;height:100%;flex-wrap:wrap;grid-gap:10px;gap:10px}.skills-content .skill-item{display:flex;flex-direction:row;flex-flow:row;padding:10px;border:solid #fff .2em;border-radius:.75em;background-color:rgba(0,0,0,.2);justify-content:start;align-items:center;flex-grow:1}.skills-content .skill-item.no-detail{justify-content:center}.skills-content .skill-item .skill-icon{display:inline-block;height:64px;justify-content:center;align-items:center}.skills-content .skill-item .skill-icon svg{width:4em !important;height:4em !important}.skills-content .skill-item .skill-icon svg path{fill:#fff !important}.skills-content .skill-item .skill-detail{margin-left:.75em}
.background-texture{position:absolute;width:100%;height:100%;top:0;left:0;background-image:url(/static/media/circuit-board.b4c8437d.svg);background-color:transparent;fill:#fff;opacity:.09;z-index:-1}
.main-content{padding:50px;background-color:#dc4c46;height:auto;z-index:1;position:relative}.main-content h1{margin:0}.main-content h1,.main-content p{position:relative;z-index:1}
.expandable{transition:max-height .3s ease-in-out;overflow:hidden}
.projects-container{display:flex;flex-direction:row;flex-wrap:wrap;grid-row-gap:1em;row-gap:1em;justify-content:space-between}.projects-container .project{width:32%}.projects-container .project .project-media{display:flex;justify-content:center;background-color:#000}.projects-container .project:hover{cursor:pointer}.projects-container .project:hover .expand-icon{animation:bobbing .5s;animation-iteration-count:infinite}.projects-container .project .expandable.collapsed{position:relative}.projects-container .project .expandable.collapsed::before{content:"";position:absolute;top:0;left:0;width:100%;height:100%;background:linear-gradient(to bottom, transparent 90%, #adb5bd)}.projects-container .project .project-body{background-color:#fafafa;padding:1em}.projects-container .project .project-body *{color:#000}.projects-container .project .project-body h1{color:#00539c;font-weight:bold}.projects-container .project .project-body .links{display:flex;justify-content:space-evenly}.projects-container .project .project-body .links button{flex:1 1;margin:1em;padding:.5em}.projects-container .project .project-date-started{background-color:#343a40;color:#fff;padding:.5em;display:flex;justify-content:space-between;align-items:center}@keyframes bobbing{0%{transform:translateY(0)}50%{transform:translateY(5px)}100%{transform:translateY(0)}}.projects-container .project .project-date-started .expand-icon{display:flex;justify-content:center;align-items:center}.projects-container .project .project-date-started .expand-icon.expanded svg{transform:rotate(180deg)}.projects-container .project .project-date-started .expand-icon svg{transform:rotate(0deg);transition:transform .3s;fill:#fff;height:1.5em;width:1.5em}
.portfolio *{font-family:"Montserrat",sans-serif;font-weight:100;box-sizing:border-box;color:#fff}
