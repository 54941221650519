@use "../../master";

.banner {
  $defaultPadding: master.$defaultPadding;
  height: auto;
  padding: $defaultPadding $defaultPadding $defaultPadding 100px;
  padding-bottom: 10px;

  background: rgb(52, 63, 145);
  background: linear-gradient(90deg, rgba(52, 63, 145, 1) 0%, rgba(65, 205, 222, 1) 100%);

  color: white;

  position: relative;

  .particles {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .bannerContainer {
    display: flex;

    margin: 0 20px;
    height: 100%;

    justify-content: space-between;
    align-items: center;

    flex-direction: row;
    flex: 1;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .overviewContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      flex: 1;
      height: 100%;

      h1 {
        font-size: 3rem;
        font-weight: 100;
        margin: 0;
      }

      .overview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        * {
          width: 100%;
        }

        flex-grow: 1;
        .name {
          display: flex;
          flex-grow: flex-start;
          justify-content: left;
          align-items: end;

          border-bottom: 1px solid white;
        }

        .statement {
          flex-grow: 1;
        }
      }
    }

    .skillsContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      flex: 1;
      padding: 0 20px;
      height: 100%;

      position: relative;
      z-index: 100;

      .skillsTitle {
        width: 100%;
        h1 {
          font-size: 3rem;
          margin: 0;
        }
      }

      .skillsDetail {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
    }
  }
}
