.skills-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: 10px;

  .skill-item {
    display: flex;
    flex-direction: row;
    flex-flow: row;
    padding: 10px;

    border: solid white 0.2em;
    border-radius: 0.75em;

    background-color: rgba($color: #000000, $alpha: 0.2);

    justify-content: start;
    align-items: center;
    flex-grow: 1;

    &.no-detail {
      justify-content: center;
    }

    .skill-icon {
      display: inline-block;
      height: 64px;
      justify-content: center;
      align-items: center;

      svg {
        $size: 4em;
        width: $size !important;
        height: $size !important;

        path {
          fill: white !important;
        }
      }
    }

    .skill-detail {
      margin-left: 0.75em;
    }
  }
}
